:root {
  --headerHeight: 142px;
  --verticalSpaceLarge: 110px;
  --horizontalPadding: 110px;
  --pageRadius: 20px;
  --productFaderSpaceTop: 270px;
}
@media (max-width: 1023px) {
  :root {
    --headerHeight: 100px;
    --verticalSpaceLarge: 80px;
    --horizontalPadding: 40px;
    --productFaderSpaceTop: 140px;
  }
}
@media (max-width: 767px) {
  :root {
    --headerHeight: 64px;
    --verticalSpaceLarge: 60px;
    --horizontalPadding: 20px;
    --productFaderSpaceTop: 70px;
  }
}
:root {
  --deskWidth: 1344px;
  --deskSpace: 80px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
@media (max-width: 1023px) {
  :root {
    --deskSpace: 40px;
  }
}
@media (max-width: 767px) {
  :root {
    --deskSpace: 20px;
  }
}
:root {
  --spacePart: 15px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 10px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root {
  --spaceUnit: 25px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 20px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 20px;
  }
}
:root {
  --spaceTotal: 40px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 30px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 30px;
  }
}
:root {
  --js-breakpoint: breakpointLarge;
}
@media (max-width: 1023px) {
  :root {
    --js-breakpoint: breakpointMedium;
  }
}
@media (max-width: 767px) {
  :root {
    --js-breakpoint: breakpointSmall;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: transparent;
  font-size: 18px;
  line-height: 1.44444444;
  font-family: inherit;
  cursor: pointer;
  color: #000;
  padding: 5px 40px;
  appearance: none;
  border-radius: 10000px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  border: 2px solid #000;
  transition: all 0.24s;
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #D80011, #D80011);
}
.button:hover,
.button:focus {
  background-size: 100% 100%;
  border-color: #D80011;
  color: #fff;
}
/* outfit-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  src: url('/extras/fonts/outfit-v11-latin-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* outfit-500 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  src: url('/extras/fonts/outfit-v11-latin-500.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* outfit-600 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  src: url('/extras/fonts/outfit-v11-latin-600.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: #D80011;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: 600;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 20px;
  font-family: 'Outfit', sans-serif;
  line-height: 1.4;
}
.unit caption {
  display: none;
}
.flag {
  background: #D80011;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.cbdModule {
  float: left;
  width: 100%;
}
#view .cbdModule.is-empty {
  display: none;
}
.cbDynamicContent__element {
  float: left;
  width: 100%;
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.area .unit {
  float: left;
  width: 100%;
  margin-top: var(--spaceUnit);
  margin-bottom: var(--spaceUnit);
  position: relative;
}
.unit__background {
  float: left;
  width: 100%;
}
.unit__content {
  float: left;
  width: 100%;
}
.unit__head,
.unit__body,
.unit__foot {
  float: left;
  width: 100%;
}
.unit--fold div.less,
.unit--fold div.more {
  float: left;
  width: 100%;
}
.unit--fold .ctrl {
  float: left;
  width: 100%;
}
.unit .part {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  position: relative;
}
.part__content {
  float: left;
  width: 100%;
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
#edit .part video {
  pointer-events: none;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
  margin-top: 6px;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid #000;
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.part.cb-youtube iframe,
.part.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.part.cb-share {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.part.cb-share .cb-service {
  float: left;
  margin-left: 16px;
}
.part.cb-share .cb-service:first-child {
  margin-left: 0;
}
.part.cb-share .cb-service * {
  float: left;
}
.part.cb-share .cb-service a {
  float: left;
  width: 50px;
  height: 50px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 10000px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.part.cb-share .cb-service a:hover {
  filter: invert(1);
}
.part.cb-share .cb-service.cb-service-facebook-share a {
  background-size: auto 24px;
  background-image: url(/images/cb-default/socialShare-facebook-black.svg);
}
.part.cb-share .cb-service.cb-service-twitter a {
  background-size: auto 18px;
  background-image: url(/images/cb-default/socialShare-twitter-black.svg);
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 14px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 14px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
#expo {
  float: left;
  width: 100%;
  position: relative;
}
#slides {
  float: left;
  width: 100%;
  height: 900px !important;
  max-width: 100% !important;
  max-height: 100% !important;
  position: relative;
  overflow: hidden;
}
#slides:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(85, 85, 85, 0.25);
  mix-blend-mode: multiply;
  pointer-events: none;
}
.layout3 #slides {
  height: 500px !important;
}
@media (max-width: 1023px) {
  #slides {
    height: 500px !important;
  }
  .layout3 #slides {
    height: 300px !important;
  }
}
@media (max-width: 767px) {
  #slides {
    height: calc(var(--vh, 1vh) * 100) !important;
  }
  .layout3 #slides {
    height: 240px !important;
  }
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
  animation-name: moodScale;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes moodScale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
#expo div.link {
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  background: #D80011;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  display: none;
}
#expo a.link.prev {
  left: 20px;
}
#expo a.link.next {
  right: 20px;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 100px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 18px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #D80011;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
  display: none;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'Outfit', sans-serif;
  line-height: 1.42857143;
  color: #000;
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #D80011;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #D80011;
  font-size: 16px;
  font-weight: 600;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  background-color: transparent;
  font-size: 18px;
  line-height: 1.44444444;
  font-family: inherit;
  cursor: pointer;
  color: #000;
  padding: 5px 40px;
  appearance: none;
  border-radius: 10000px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  border: 2px solid #000;
  transition: all 0.24s;
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #D80011, #D80011);
  line-height: 1.42857143;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  background-size: 100% 100%;
  border-color: #D80011;
  color: #fff;
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input[type*="radio"],
#disp input[type*="checkbox"] {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1023px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'Outfit', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 1024px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  font-size: 18px;
  line-height: 1.44444444;
  padding-bottom: 5px;
}
@media (min-width: 1024px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 40px;
    padding-top: 6px;
    padding-bottom: 6px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  min-height: 40px;
  padding: 5px 16px;
  appearance: none;
  border-radius: var(--pageRadius);
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.unit.form textarea {
  padding: 10px 16px;
}
@media (min-width: 1024px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 1024px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 28px;
  vertical-align: top;
}
@media (min-width: 1024px) {
  .unit.form div.tick.tile input {
    top: 6px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  line-height: 1.4;
  background: #fff;
  height: 40px;
  border: 1px solid #000;
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  width: 100%;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 1024px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form .submit {
  float: right;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  background-color: transparent;
  font-size: 18px;
  line-height: 1.44444444;
  font-family: inherit;
  cursor: pointer;
  color: #000;
  padding: 5px 40px;
  appearance: none;
  border-radius: 10000px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  border: 2px solid #000;
  transition: all 0.24s;
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #D80011, #D80011);
}
.unit.form .submit:hover,
.unit.form .submit:focus {
  background-size: 100% 100%;
  border-color: #D80011;
  color: #fff;
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: #000;
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  background-color: transparent;
  font-size: 18px;
  line-height: 1.44444444;
  font-family: inherit;
  cursor: pointer;
  color: #000;
  padding: 5px 40px;
  appearance: none;
  border-radius: 10000px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  border: 2px solid #000;
  transition: all 0.24s;
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #D80011, #D80011);
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  background-size: 100% 100%;
  border-color: #D80011;
  color: #fff;
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 10px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: 600;
}
.table td {
  padding: 5px 10px;
  vertical-align: top;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: 600;
}
@media (max-width: 767px) {
  .part--table .table {
    font-size: 2.60756193vw;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
body.cb-toggle-target-active {
  overflow: hidden;
}
.logo {
  float: left;
  height: 42px;
  position: relative;
}
@media (max-width: 767px) {
  .logo {
    height: 24px;
  }
}
.logo__image {
  width: auto;
  height: 100%;
  transition: all 0.4s;
}
.logo__image.logo__image--white {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  opacity: 0;
}
#head {
  float: left;
  max-width: 100%;
  width: 940px;
}
.scroll-animated {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.6s, transform 0.6s;
}
.scroll-animated.scroll-animated--active {
  opacity: 1;
  transform: translateY(0);
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  line-height: 1.4;
  color: #000;
  overflow: hidden;
  hyphens: none;
  opacity: 0.01;
  transition: all 0.3s;
}
.show-content .wrapper {
  opacity: 1;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: var(--deskMaxWidth);
  width: var(--deskWidth);
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
  transition: all 0.4s;
}
.section--pageLead {
  z-index: 5;
  opacity: 0;
  transform: translateY(60px);
  transition: all 0.6s 0.3s;
}
.show-content .section--pageLead {
  transform: translateY(0);
  opacity: 1;
}
.section--three {
  z-index: 5;
  opacity: 0;
  transform: translateY(60px);
  transition: all 0.6s 0.3s;
}
.show-content .section--three {
  transform: translateY(0);
  opacity: 1;
}
.section--footer {
  background-color: #848282;
  color: #fff;
}
.section--footer a:hover,
.section--footer a:focus {
  color: #000;
}
.section--lightgray {
  background-color: #F4F2F2;
  padding-top: calc(var(--verticalSpaceLarge) - var(--spaceTotal));
  padding-bottom: calc(var(--verticalSpaceLarge) - var(--spaceTotal));
  display: none;
}
#edit.layout1 .section--lightgray.section--area1,
#view.layout1:not(.area1--empty) .section--lightgray.section--area1,
#edit.layout5 .section--lightgray.section--area1,
#view.layout5:not(.area1--empty) .section--lightgray.section--area1 {
  display: block;
}
.section--productFilter {
  background-color: #F4F2F2;
}
.content {
  float: left;
  width: 100%;
}
.section--header .content {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: var(--headerHeight);
}
.section--pageLead .content {
  background-color: #fff;
  box-sizing: border-box;
  padding: 0 var(--horizontalPadding);
  margin-top: -70px;
  border-radius: var(--pageRadius);
}
.section--three .content {
  background-color: #fff;
  box-sizing: border-box;
  margin-top: -70px;
  border-radius: var(--pageRadius);
  justify-content: space-evenly;
  align-items: flex-start;
  display: none;
}
.layout3 .section--three .content {
  display: flex;
}
@media (max-width: 767px) {
  .section--three .content {
    flex-direction: column;
    align-items: center;
  }
}
.subcontent {
  float: left;
  width: 100%;
}
.section--three .subcontent {
  width: calc(100% - 40px);
  max-width: 388px;
}
.section--three .subcontent.subcontent--1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.headerlinks {
  float: left;
  display: flex;
  align-items: center;
}
.headerlinks div.sub1 {
  float: left;
  display: flex;
}
.headerlinks div.sub1 > .item {
  float: left;
  margin-right: 10px;
}
.headerlinks div.sub1 > .item.exit {
  margin-right: 0;
}
.headerlinks div.sub1 > .item > .menu {
  float: left;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10000px;
  text-decoration: none;
  transition: all 0.24s;
  border: 2px solid transparent;
  font-size: 18px;
  line-height: 1.44444444;
}
.headerlinks div.sub1 > .item > .menu:hover,
.headerlinks div.sub1 > .item > .menu:focus,
.headerlinks div.sub1 > .item > .menu.path {
  background-color: #fff;
  color: #000;
}
@media (max-width: 1023px) {
  .headerlinks div.sub1 {
    display: none;
  }
}
.cbdModule--headerButton {
  width: auto;
  margin-left: 36px;
  margin-right: 36px;
}
.cbdModule--headerButton .open {
  float: left;
  background-color: transparent;
  font-size: 18px;
  line-height: 1.44444444;
  font-family: inherit;
  cursor: pointer;
  color: #000;
  padding: 5px 40px;
  appearance: none;
  border-radius: 10000px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  border: 2px solid #000;
  transition: all 0.24s;
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #D80011, #D80011);
  background-image: linear-gradient(to right, #fff, #fff);
  border-color: #fff;
  color: #fff;
}
.cbdModule--headerButton .open:hover,
.cbdModule--headerButton .open:focus {
  background-size: 100% 100%;
  border-color: #D80011;
  color: #fff;
}
.cbdModule--headerButton .open:hover,
.cbdModule--headerButton .open:focus {
  border-color: #fff;
  color: #000;
}
@media (max-width: 1023px) {
  .cbdModule--headerButton {
    display: none;
  }
}
.moodcontent {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 3;
  transform: translate(-60%, -50%);
  width: var(--deskWidth);
  max-width: var(--deskMaxWidth);
  box-sizing: border-box;
  padding: 0 var(--horizontalPadding);
  opacity: 0;
  transition: all 0.6s 0.2s;
}
.show-content .moodcontent {
  opacity: 1;
  transform: translate(-50%, -50%);
}
@media (max-width: 767px) {
  .moodcontent {
    padding: 0;
  }
}
.cbdModule--pageLead {
  margin-top: var(--verticalSpaceLarge);
  margin-bottom: var(--verticalSpaceLarge);
}
@media (max-width: 1023px) {
  .cbdModule--pageLead {
    margin-top: var(--horizontalPadding);
  }
}
.section--header.cb-scroll-triggered--active,
body.cb-toggle-target-active .section--header {
  background-color: #D80011;
}
.section--header.cb-scroll-triggered--active .logo__image,
body.cb-toggle-target-active .section--header .logo__image {
  opacity: 0;
}
.section--header.cb-scroll-triggered--active .logo__image.logo__image--white,
body.cb-toggle-target-active .section--header .logo__image.logo__image--white {
  opacity: 1;
}
.section--header.cb-scroll-triggered--active .headerlinks div.sub1 > .item > .menu:hover,
body.cb-toggle-target-active .section--header .headerlinks div.sub1 > .item > .menu:hover,
.section--header.cb-scroll-triggered--active .headerlinks div.sub1 > .item > .menu:focus,
body.cb-toggle-target-active .section--header .headerlinks div.sub1 > .item > .menu:focus,
.section--header.cb-scroll-triggered--active .headerlinks div.sub1 > .item > .menu.path,
body.cb-toggle-target-active .section--header .headerlinks div.sub1 > .item > .menu.path {
  border-color: #fff;
  background-color: transparent;
  color: #fff;
}
.footcontent {
  float: left;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;
  font-size: 16px;
  line-height: 1.5;
}
.footsection {
  float: left;
  width: 100%;
}
.footsection--2 {
  margin-top: 40px;
}
.footarea {
  width: calc(100% + 30px);
  margin-left: -15px;
  display: flex;
  flex-wrap: wrap;
}
.footunit {
  margin-left: 15px;
  margin-right: 15px;
  width: calc((100% / 6) - 30px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 767px) {
  .footunit {
    width: calc(100% - 30px) !important;
  }
}
.footunit--1 {
  width: calc(100% - 30px);
}
.footunit--2 {
  width: calc(20% - 30px);
}
@media (max-width: 1023px) {
  .footunit--2 {
    width: calc(50% - 30px);
  }
}
.footunit--3 {
  width: calc(20% - 30px);
  margin-left: 40%;
}
@media (max-width: 1023px) {
  .footunit--3 {
    width: calc(50% - 30px);
    margin-left: 15px;
  }
}
@media (max-width: 767px) {
  .footunit--3 {
    margin-top: 24px;
  }
}
.footlogo {
  float: left;
  height: 42px;
}
.footlogo__image {
  float: left;
  height: 100%;
  width: auto;
}
.vcard {
  float: left;
}
#services {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#services .meta {
  float: left;
}
#social {
  float: left;
  margin-top: auto;
}
#social .meta {
  float: left;
  width: 36px;
  height: 36px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/social-linkedin-white.svg);
  margin-right: 20px;
  box-sizing: border-box;
  border: 1px solid #fff;
  border-radius: 10000px;
}
#social .meta:last-child {
  margin-right: 0;
}
#social .meta:hover,
#social .meta:focus {
  background-image: url(/images/social-linkedin-black.svg);
  border-color: #000;
}
@media (max-width: 767px) {
  #social {
    margin-top: 24px;
  }
}
.service_linkedinch {
  margin-top: calc(var(--spaceTotal) * 1 + 23px);
  margin-bottom: calc(var(--spaceTotal) * 1);
  float: left;
  width: 36px;
  height: 36px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/social-linkedin-white.svg);
  margin-right: 20px;
  box-sizing: border-box;
  border: 1px solid #fff;
  border-radius: 10000px;
}
.service_linkedinch:last-child {
  margin-right: 0;
}
.service_linkedinch:hover,
.service_linkedinch:focus {
  background-image: url(/images/social-linkedin-black.svg);
  border-color: #000;
}
.service_linkedinuk {
  margin-top: calc(var(--spaceTotal) * 1);
  margin-bottom: calc(var(--spaceTotal) * 1);
  float: left;
  width: 36px;
  height: 36px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/social-linkedin-white.svg);
  margin-right: 20px;
  box-sizing: border-box;
  border: 1px solid #fff;
  border-radius: 10000px;
}
.service_linkedinuk:last-child {
  margin-right: 0;
}
.service_linkedinuk:hover,
.service_linkedinuk:focus {
  background-image: url(/images/social-linkedin-black.svg);
  border-color: #000;
}
.footunit div.sub2 {
  width: calc(100% + 30px);
  margin-left: -15px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-top: 16px;
  position: relative;
  margin-bottom: calc(var(--spaceTotal) * 1);
}
.footunit div.sub2:after {
  content: '';
  position: absolute;
  left: 15px;
  top: 0;
  z-index: 2;
  width: 100px;
  height: 2px;
  background-color: #fff;
}
.footunit div.sub2 > .item {
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footunit div.sub2 > .item > .menu {
  float: left;
  text-decoration: none;
  font-weight: 600;
}
@media (max-width: 1023px) {
  .footunit div.sub2 > .item {
    margin-top: 0;
  }
}
.footunit div.sub3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 24px;
}
.footunit div.sub3 > .item > .menu {
  text-decoration: none;
  font-size: 16px;
  line-height: 1.125;
  padding: 3px 0;
}
.part--productImage {
  float: left;
  width: 100%;
  margin-top: var(--verticalSpaceLarge);
}
.part--productImage .cb-image-figure {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.part--productImage .cb-image-container {
  width: auto;
}
.cbdModule--productText {
  margin-top: 40px;
  text-align: center;
}
.productPins {
  float: left;
  width: 100%;
  margin-top: 40px;
}
.productPins__content {
  float: left;
  width: calc(100% + 20px);
  margin: -10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.productPin {
  float: left;
  margin: 10px;
  width: 50px;
}
.backlink {
  float: left;
  margin-top: 60px;
  margin-bottom: var(--spaceTotal);
  font-size: 18px;
  line-height: 1.44444444;
  color: #D80011;
  text-decoration: none;
  padding-left: 76px;
  padding-right: 10px;
  background-size: auto 16px;
  background-position: 0 5px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-red.svg);
  transition: all 0.24s;
  background-image: url(/images/arrow-left-red.svg);
  cursor: pointer;
}
.backlink:hover,
.backlink:focus {
  padding-right: 0;
  padding-left: 86px;
  color: #000 !important;
  background-image: url(/images/arrow-right-black.svg) !important;
}
.backlink:hover,
.backlink:focus {
  background-image: url(/images/arrow-left-black.svg) !important;
}
a {
  color: inherit;
  text-decoration: underline;
  text-underline-offset: 3px;
}
a:hover,
a:focus {
  color: #D80011;
}
h1 {
  font-size: 60px;
  line-height: 1.2;
  font-weight: 500;
  padding-top: 20px;
  color: #fff;
  position: relative;
}
h1:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 312px;
  max-width: 100%;
  height: 5px;
  background-color: #fff;
}
h2 {
  font-weight: 600;
  font-size: 32px;
  line-height: 1.4375;
}
h3 {
  font-weight: 600;
}
h4 {
  font-weight: normal;
  text-decoration: underline;
  text-underline-offset: 3px;
}
.loud {
  font-size: 32px;
  line-height: 1.4375;
  color: #D80011;
}
.area--one {
  display: flex;
  flex-wrap: wrap;
}
.area--one .unitOne {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  background-color: #fff;
  border-radius: var(--pageRadius);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  overflow: hidden;
}
.area--one .unitOne .unit__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.area--one .unitOne .unit__body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.area--one .unitOne + .unitOne + .unitOne + .unitOne {
  margin-top: calc((var(--spaceTotal) - 30px) * -1);
}
.area--one .unitOne .unit__background {
  height: 332px;
}
.area--one .unitOne .unit__background * {
  height: 100%;
}
.area--one .unitOne .unit__background img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.area--one .unitOne .unit__content {
  padding-top: 40px;
  padding-bottom: 40px;
  box-sizing: border-box;
}
.area--one .unitOne .unit__body {
  margin-top: calc(var(--spacePart) * -1);
  margin-bottom: calc(var(--spacePart) * -1);
}
@media (max-width: 1023px) {
  .area--one .unitOne {
    padding-left: 20px;
    padding-right: 20px;
  }
  .area--one .unitOne + .unitOne + .unitOne {
    margin-top: calc((var(--spaceTotal) - 30px) * -1);
  }
  .area--one .unitOne .unit__background {
    height: 220px;
  }
  .area--one .unitOne .unit__content {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .area--one .unitOne + .unitOne {
    margin-top: calc((var(--spaceTotal) - 30px) * -1);
  }
}
.area--one .unitTwo {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
  background-color: #fff;
  border-radius: var(--pageRadius);
  box-sizing: border-box;
  padding: 0 30px calc(30px - var(--spacePart));
}
.area--one .unitTwo + .unitTwo + .unitTwo {
  margin-top: calc((var(--spaceTotal) - 30px) * -1);
}
.area--one .unitTwo .unit__background {
  margin-bottom: var(--spacePart);
  height: 332px;
  position: relative;
}
.area--one .unitTwo .unit__background * {
  height: 100%;
}
.area--one .unitTwo .unit__background img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.area--one .unitTwo .unit__background:after {
  content: '';
  position: absolute;
  right: 36px;
  top: 100%;
  margin-top: -40px;
  z-index: 2;
  width: 80px;
  aspect-ratio: 0.72072072;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
@media (max-width: 1023px) {
  .area--one .unitTwo {
    padding: 0 20px calc(20px - var(--spacePart));
  }
  .area--one .unitTwo .unit__background {
    height: 220px;
  }
  .area--one .unitTwo .unit__background:after {
    right: 0;
    width: 50px;
    margin-top: -24px;
  }
}
@media (max-width: 767px) {
  .area--one .unitTwo + .unitTwo {
    margin-top: calc((var(--spaceTotal) - 30px) * -1);
  }
}
.area--one .unitTwo--subjectHealth .unit__background:after {
  background-image: url(/images/pin-subjectType1-2024-02-15.svg);
}
.area--one .unitTwo--subjectGastro .unit__background:after {
  background-image: url(/images/pin-subjectType2-2024-02-15.svg);
}
.area--one .unitTwo--subjectHotel .unit__background:after {
  background-image: url(/images/pin-subjectType3-2024-02-15.svg);
}
.area--one .unitTwo--subjectClothes .unit__background:after {
  background-image: url(/images/pin-subjectType4-2024-02-15.svg);
}
.area--one .unitThree {
  text-align: center;
}
.area--two {
  display: flex;
  flex-wrap: wrap;
}
.area--two .unit {
  margin-top: 0;
  margin-bottom: 0;
}
.area--two .unitOne {
  position: relative;
  padding-top: calc(var(--verticalSpaceLarge) - var(--spacePart));
  padding-bottom: calc(var(--verticalSpaceLarge) - var(--spacePart));
}
.area--two .unitOne:before {
  content: '';
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100%;
  pointer-events: none;
}
.area--two .unitOne--1-1 {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.area--two .unitOne--1-1:before {
  left: 50%;
  margin-left: -50vw;
}
.area--two .unitOne--1-1 .unit__content {
  max-width: 694px;
}
.area--two .unitOne--1-2 {
  box-sizing: border-box;
  padding-right: var(--horizontalPadding);
  display: flex;
  flex-direction: column;
}
.area--two .unitOne--1-2 .unit__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.area--two .unitOne--1-2 .unit__body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.area--two .unitOne--1-2:before {
  right: 0;
}
.area--two .unitOne--1-2 .part--widget.cb-googlemaps {
  align-self: flex-end;
  flex-grow: 1;
  margin-top: calc((var(--verticalSpaceLarge) - var(--spacePart)) * -1);
  margin-bottom: calc((var(--verticalSpaceLarge) - var(--spacePart)) * -1);
  width: 50vw;
  margin-right: calc(var(--horizontalPadding) * -1);
}
.area--two .unitOne--1-2 .part--widget.cb-googlemaps .cb-googlemapscontainer,
.area--two .unitOne--1-2 .part--widget.cb-googlemaps .cb-undraggable {
  height: 100% !important;
}
.area--two .unitOne--1-2 + .unitOne--1-2 {
  padding-right: 0;
  padding-left: var(--horizontalPadding);
}
.area--two .unitOne--1-2 + .unitOne--1-2:before {
  right: initial;
  left: 0;
}
.area--two .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 {
  padding-left: 0;
  padding-right: var(--horizontalPadding);
}
.area--two .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2:before {
  left: initial;
  right: 0;
}
.area--two .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 {
  padding-right: 0;
  padding-left: var(--horizontalPadding);
}
.area--two .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2:before {
  right: initial;
  left: 0;
}
.area--two .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 {
  padding-left: 0;
  padding-right: var(--horizontalPadding);
}
.area--two .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2:before {
  left: initial;
  right: 0;
}
.area--two .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 {
  padding-right: 0;
  padding-left: var(--horizontalPadding);
}
.area--two .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2:before {
  right: initial;
  left: 0;
}
.area--two .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 {
  padding-left: 0;
  padding-right: var(--horizontalPadding);
}
.area--two .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2:before {
  left: initial;
  right: 0;
}
.area--two .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 {
  padding-right: 0;
  padding-left: var(--horizontalPadding);
}
.area--two .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2 + .unitOne--1-2:before {
  right: initial;
  left: 0;
}
@media (max-width: 767px) {
  .area--two .unitOne--1-2 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .area--two .unitOne--1-2:before {
    left: 50% !important;
    right: initial !important;
    margin-left: -50vw !important;
  }
  .area--two .unitOne--1-2 .part--widget.cb-googlemaps {
    width: 100vw;
    aspect-ratio: 1;
  }
}
.area--two .unitOne--backgroundColorWhite:before {
  background-color: #fff;
}
.area--two .unitOne--backgroundColorLightgray:before {
  background-color: #F4F2F2;
}
.area--two .unitOne--backgroundColorGray:before {
  background-color: #E5E3E3;
}
.area--two .unitOne--backgroundColorDarkgray:before {
  background-color: #BCBBBC;
}
.area--two .unit--verticalSpaceBottomSmall {
  padding-bottom: 0;
  margin-bottom: calc(var(--spacePart) * -1);
}
.area--two .unitTwo {
  position: relative;
  display: flex;
}
.area--two .unitTwo:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -50vw;
  width: 100vw;
  height: 100%;
  pointer-events: none;
}
.area--two .unitTwo .unit__background {
  position: relative;
  z-index: 2;
  aspect-ratio: 1;
  flex-shrink: 0;
  width: calc(50% + ((1800px - var(--deskWidth)) / 2));
  max-width: 50vw;
}
.area--two .unitTwo .unit__background * {
  height: 100%;
}
.area--two .unitTwo .unit__background img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.area--two .unitTwo .unit__content {
  position: relative;
  z-index: 2;
  flex-shrink: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-top: var(--verticalSpaceLarge);
  padding-bottom: var(--verticalSpaceLarge);
}
.area--two .unitTwo .unit__body {
  margin-top: calc(var(--spacePart) * -1);
  margin-bottom: calc(var(--spacePart) * -1);
}
@media (max-width: 767px) {
  .area--two .unitTwo {
    flex-direction: column;
    align-items: center;
  }
  .area--two .unitTwo .unit__background {
    width: 100vw;
    max-width: 100vw;
  }
}
.area--two .unitTwo--backgroundColorWhite:before {
  background-color: #fff;
}
.area--two .unitTwo--backgroundColorLightgray:before {
  background-color: #F4F2F2;
}
.area--two .unitTwo--backgroundColorGray:before {
  background-color: #E5E3E3;
}
.area--two .unitTwo--backgroundColorDarkgray:before {
  background-color: #BCBBBC;
}
.area--two .unitThree {
  padding-top: var(--verticalSpaceLarge);
  padding-bottom: var(--verticalSpaceLarge);
  position: relative;
  text-align: center;
}
.area--two .unitThree:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -50vw;
  width: 100vw;
  height: 100%;
  pointer-events: none;
}
.area--two .unitThree .unit__body {
  display: flex;
  flex-wrap: wrap;
  margin-top: -30px;
  margin-bottom: -30px;
}
.area--two .unitThree .part {
  margin-top: 30px;
  margin-bottom: 30px;
}
.area--two .unitThree .part.pict .cb-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.area--two .unitThree--backgroundColorWhite:before {
  background-color: #fff;
}
.area--two .unitThree--backgroundColorLightgray:before {
  background-color: #F4F2F2;
}
.area--two .unitThree--backgroundColorGray:before {
  background-color: #E5E3E3;
}
.area--two .unitThree--backgroundColorDarkgray:before {
  background-color: #BCBBBC;
}
.area--two .unit--form {
  position: relative;
  padding-top: calc(var(--verticalSpaceLarge) - var(--spacePart));
  padding-bottom: calc(var(--verticalSpaceLarge) - var(--spacePart));
  padding-left: var(--horizontalPadding);
  box-sizing: border-box;
}
.area--two .unit--form:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  pointer-events: none;
  background-color: #E5E3E3;
}
.area--two .unit--form .unit__content {
  position: relative;
  z-index: 2;
}
@media (max-width: 767px) {
  .area--two .unit--form {
    padding-left: 0;
  }
  .area--two .unit--form:before {
    left: 50%;
    margin-left: -50vw;
  }
}
.unit .part--linkArrow {
  font-size: 18px;
  line-height: 1.44444444;
}
.unit .part--linkArrow .open {
  display: inline-block;
  font-size: 18px;
  line-height: 1.44444444;
  color: #D80011;
  text-decoration: none;
  padding-left: 76px;
  padding-right: 10px;
  background-size: auto 16px;
  background-position: 0 5px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-red.svg);
  transition: all 0.24s;
}
.unit .part--linkArrow .open:hover,
.unit .part--linkArrow .open:focus {
  padding-right: 0;
  padding-left: 86px;
  color: #000 !important;
  background-image: url(/images/arrow-right-black.svg) !important;
}
.area--one .unitTwo--subjectHealth.unit .part--linkArrow .open {
  color: #288E7F;
  background-image: url(/images/arrow-right-health.svg);
}
.area--one .unitTwo--subjectGastro.unit .part--linkArrow .open {
  color: #9B876B;
  background-image: url(/images/arrow-right-gastro.svg);
}
.area--one .unitTwo--subjectHotel.unit .part--linkArrow .open {
  color: #7C58A7;
  background-image: url(/images/arrow-right-hotel.svg);
}
.area--one .unitTwo--subjectClothes.unit .part--linkArrow .open {
  color: #498CB5;
  background-image: url(/images/arrow-right-clothes.svg);
}
.unit .part--linkButton .open {
  display: inline-block;
  background-color: transparent;
  font-size: 18px;
  line-height: 1.44444444;
  font-family: inherit;
  cursor: pointer;
  color: #000;
  padding: 5px 40px;
  appearance: none;
  border-radius: 10000px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  border: 2px solid #000;
  transition: all 0.24s;
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #D80011, #D80011);
}
.unit .part--linkButton .open:hover,
.unit .part--linkButton .open:focus {
  background-size: 100% 100%;
  border-color: #D80011;
  color: #fff;
}
.unit .part--file.load {
  font-size: 18px;
  line-height: 1.44444444;
}
.unit .part--file.load .load {
  display: inline-block;
  padding-left: 36px;
  background-size: 24px 24px;
  background-position: 0 1px;
  background-repeat: no-repeat;
  background-image: url(/images/load-black-2024-02-15.svg);
  text-decoration: none;
}
.unit .part--file.load .load:hover,
.unit .part--file.load .load:focus {
  background-image: url(/images/load-red-2024-02-15.svg);
}
.unit .part--linkArrow,
.unit .part--linkButton,
.unit .part--file.load {
  margin-top: auto;
  padding-top: calc(80px - var(--spacePart));
}
.unit .part--linkArrow + .part--linkArrow,
.unit .part--linkButton + .part--linkArrow,
.unit .part--file.load + .part--linkArrow,
.unit .part--linkArrow + .part--linkButton,
.unit .part--linkButton + .part--linkButton,
.unit .part--file.load + .part--linkButton,
.unit .part--linkArrow + .part--file.load,
.unit .part--linkButton + .part--file.load,
.unit .part--file.load + .part--file.load {
  margin-top: 0;
  padding-top: calc(20px - var(--spacePart));
}
@media (max-width: 1023px) {
  .unit .part--linkArrow,
  .unit .part--linkButton,
  .unit .part--file.load {
    padding-top: calc(60px - var(--spacePart));
  }
  .unit .part--linkArrow + .part--linkArrow,
  .unit .part--linkButton + .part--linkArrow,
  .unit .part--file.load + .part--linkArrow,
  .unit .part--linkArrow + .part--linkButton,
  .unit .part--linkButton + .part--linkButton,
  .unit .part--file.load + .part--linkButton,
  .unit .part--linkArrow + .part--file.load,
  .unit .part--linkButton + .part--file.load,
  .unit .part--file.load + .part--file.load {
    padding-top: calc(14px - var(--spacePart));
  }
}
.part--textWithLineTrue {
  padding-top: 16px;
  position: relative;
}
.part--textWithLineTrue:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 140px;
  height: 4px;
  background-color: #000;
}
.area--two .unitOne--1-1 .part--textWithLineTrue:before,
.cbdModule--productTeaserSimilarTitle.part--textWithLineTrue:before,
.area--one .unitThree .part--textWithLineTrue:before {
  left: 50%;
  transform: translateX(-50%);
}
.togglenavigation {
  float: left;
  position: relative;
  width: 30px;
  height: 22px;
  margin-left: 140px;
  cursor: pointer;
}
@media (max-width: 1023px) {
  .togglenavigation {
    margin-left: 0;
  }
}
.tline {
  position: absolute;
  width: 100%;
  height: 6px;
  background-color: #fff;
  transition: all 0.2s 0.2s, transform 0.2s, background 0.4s;
}
.tline--1 {
  top: 0;
}
.tline--2 {
  bottom: 0;
}
.togglenavigation.cb-toggle-active .tline {
  transition: all 0.2s, transform 0.2s 0.2s, background 0.4s;
}
.togglenavigation.cb-toggle-active .tline--1 {
  top: 50%;
  margin-top: -3px;
  transform: rotate(-45deg);
}
.togglenavigation.cb-toggle-active .tline--2 {
  bottom: 50%;
  margin-bottom: -3px;
  transform: rotate(45deg);
}
.navigation {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1999;
  overflow: hidden;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  background-color: #D80011;
  color: #fff;
  transform: translateY(-100%);
  transition: all 0.6s;
}
.navigation::-webkit-scrollbar {
  display: none;
}
body.cb-toggle-target-active .navigation {
  transform: translateY(0);
}
.navigation__wrapper {
  float: left;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 220px 0 40px;
  box-sizing: border-box;
}
@media (max-width: 767px) {
  .navigation__wrapper {
    padding-top: 80px;
  }
}
.navigation__desk {
  float: left;
  width: var(--deskWidth);
  max-width: var(--deskMaxWidth);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.navigation__content {
  float: left;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.navigation__section {
  float: left;
  width: 100%;
  opacity: 0;
  transform: translateY(-100px);
  transition: all 0.2s;
}
body.cb-toggle-target-active .navigation__section {
  opacity: 1;
  transform: translateY(0);
}
body.cb-toggle-target-active .navigation__section--1 {
  transition: all 0.4s 0.2s;
}
.navigation__section--2 {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
body.cb-toggle-target-active .navigation__section--2 {
  transition: all 0.4s 0.3s;
}
.navivcard {
  float: left;
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  line-height: 1.5;
}
.navivcard a {
  text-decoration: none;
}
.navivcard a:hover,
.navivcard a:focus {
  color: #000;
}
@media (max-width: 767px) {
  .navivcard {
    flex-direction: column;
    align-items: flex-start;
    font-size: 15px;
    line-height: 1.46666667;
  }
}
.navivcard__part {
  float: left;
}
.navivcard__part--1 {
  margin-right: 64px;
  padding-left: 50px;
  background-size: 20px 20px;
  background-position: 0 2px;
  background-repeat: no-repeat;
  background-image: url(/images/swissFlag.svg);
}
@media (max-width: 767px) {
  .navivcard__part--1 {
    padding-top: 36px;
    padding-left: 0;
    margin-right: 0;
  }
}
.mobileLangNavi {
  float: left;
  display: none;
}
@media (max-width: 1023px) {
  .mobileLangNavi {
    display: block;
  }
}
.mobileLangNavi div.sub1 {
  float: left;
  display: flex;
}
.mobileLangNavi div.sub1 > .item {
  float: left;
  margin-right: 10px;
}
.mobileLangNavi div.sub1 > .item.exit {
  margin-right: 0;
}
.mobileLangNavi div.sub1 > .item > .menu {
  float: left;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  width: 36px;
  height: 36px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10000px;
  text-decoration: none;
  transition: all 0.24s;
  border: 2px solid transparent;
  font-size: 16px;
  line-height: 1.625;
}
.mobileLangNavi div.sub1 > .item > .menu:hover,
.mobileLangNavi div.sub1 > .item > .menu:focus,
.mobileLangNavi div.sub1 > .item > .menu.path {
  border-color: #fff;
}
div.navi {
  float: left;
}
div.navi .cb-toggle {
  display: none;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
.navigation div.sub2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
  position: relative;
  -webkit-font-smoothing: antialiased;
}
.navigation div.sub2:before {
  content: '';
  position: absolute;
  left: 50px;
  top: 0;
  z-index: 2;
  width: 312px;
  height: 5px;
  background-color: #fff;
}
.navigation div.sub2 > .item {
  position: relative;
  margin-top: 10px;
}
.navigation div.sub2 > .item.init {
  margin-top: 0;
}
.navigation div.sub2 > .item:not(.item-empty) > .cb-toggle {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 72px;
  cursor: pointer;
  background-size: 100% auto;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/navitoggle.svg);
  transition: all 0.24s;
}
.navigation div.sub2 > .item:not(.item-empty) > .cb-toggle.cb-toggle-active {
  transform: rotate(-90deg);
}
.navigation div.sub2 > .item > .menu {
  text-decoration: none;
  padding-left: 50px;
  color: #fff;
  font-size: 60px;
  line-height: 1.2;
  font-weight: 500;
}
.navigation div.sub2 > .item > .menu:hover,
.navigation div.sub2 > .item > .menu:focus {
  color: #000;
}
@media (max-width: 1023px) {
  .navigation div.sub2 > .item {
    margin-top: 16px;
  }
  .navigation div.sub2 > .item:not(.item-empty) > .cb-toggle {
    width: 20px;
    height: 56px;
  }
  .navigation div.sub2 > .item > .menu {
    font-size: 50px;
    line-height: 1.12;
  }
}
@media (max-width: 767px) {
  .navigation div.sub2:before {
    left: 30px;
    width: 200px;
  }
  .navigation div.sub2 > .item {
    margin-top: 10px;
  }
  .navigation div.sub2 > .item:not(.item-empty) > .cb-toggle {
    width: 30px;
    height: 44px;
    background-size: 12px auto;
  }
  .navigation div.sub2 > .item > .menu {
    font-size: 40px;
    line-height: 1.1;
    padding-left: 30px;
  }
}
.navigation div.sub3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  padding-left: 50px;
  height: 0;
  overflow: hidden;
  transition: all 0.6s;
}
.navigation div.sub3 > .item {
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.2s;
  margin-top: 14px;
}
.navigation div.sub3 > .item.init {
  margin-top: 24px;
}
.navigation div.sub3 > .item.exit {
  margin-bottom: 24px;
}
.navigation div.sub3 > .item > .menu {
  font-size: 32px;
  line-height: 1.25;
  font-weight: 600;
  color: #000;
  text-decoration: none;
}
.navigation div.sub3 > .item > .menu:hover,
.navigation div.sub3 > .item > .menu:focus {
  color: #fff;
}
@media (max-width: 1023px) {
  .navigation div.sub3 > .item > .menu {
    font-size: 28px;
    line-height: 1.14285714;
  }
}
@media (max-width: 767px) {
  .navigation div.sub3 {
    padding-left: 30px;
  }
  .navigation div.sub3 > .item.init {
    margin-top: 16px;
  }
  .navigation div.sub3 > .item.exit {
    margin-bottom: 16px;
  }
  .navigation div.sub3 > .item > .menu {
    font-size: 22px;
    line-height: 1.18181818;
  }
}
.navigation div.sub2 > .item.cb-toggle-target-active div.sub3 {
  height: var(--js-elementHeight);
}
.navigation div.sub2 > .item.cb-toggle-target-active div.sub3 > .item {
  transition: all 0.4s 0.3s;
  opacity: 1;
  transform: translateY(0);
}
@media (max-width: 1023px) {
  .wrapper {
    font-size: 18px;
    line-height: 1.33333333;
  }
  h1 {
    font-size: 40px;
    line-height: 1.2;
    hyphens: auto;
  }
  h1:before {
    width: 200px;
  }
  h2 {
    font-size: 28px;
    line-height: 1.35714286;
  }
  .loud {
    font-size: 22px;
    line-height: 1.27272727;
    hyphens: auto;
  }
}
.subjects {
  float: left;
  width: 100%;
  display: none;
}
.layout2 .subjects {
  display: block;
}
.subject {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--verticalSpaceLarge);
}
.subject:first-child {
  margin-top: 0;
}
.subject__section {
  float: left;
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
}
@media (max-width: 767px) {
  .subject__section {
    width: 100%;
  }
}
.subject__section--1 {
  width: 100%;
  margin-bottom: -80px;
  z-index: 2;
}
@media (max-width: 767px) {
  .subject__section--side {
    display: none;
  }
}
.part--subjectImage {
  float: left;
  width: 100%;
  height: 350px;
}
.part--subjectImage * {
  height: 100%;
}
.part--subjectImage img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.part--subjectImage .placeholder {
  padding-bottom: 0;
}
@media (max-width: 1023px) {
  .part--subjectImage {
    height: 240px;
  }
}
@media (max-width: 767px) {
  .part--subjectImage {
    height: 354px;
  }
}
.subject__pin {
  position: absolute;
  left: 50%;
  top: 100%;
  z-index: 4;
  transform: translateX(-50%);
  margin-top: -74px;
  width: 150px;
}
@media (max-width: 1023px) {
  .subject__pin {
    width: 100px;
    margin-top: -50px;
  }
}
@media (max-width: 767px) {
  .subject__pin {
    left: initial;
    right: 20px;
    transform: translateX(0);
    width: 50px;
    margin-top: -28px;
  }
}
.subject__content {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: calc((var(--verticalSpaceLarge) - var(--spacePart)) + 80px) 0 calc(var(--verticalSpaceLarge) - var(--spacePart));
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.subject__content:before {
  content: '';
  position: absolute;
  top: 0;
  z-index: 1;
  pointer-events: none;
  width: 50vw;
  height: 100%;
}
@media (max-width: 767px) {
  .subject__content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .subject__content:before {
    width: 100vw;
    left: 50% !important;
    right: initial !important;
    margin-left: -50vw;
  }
}
.subject__section--2 .subject__content {
  padding-right: var(--horizontalPadding);
}
.subject__section--2 .subject__content:before {
  right: 0;
}
.subject__section--3 .subject__content {
  padding-left: var(--horizontalPadding);
}
.subject__section--3 .subject__content:before {
  left: 0;
}
.subject__section--main .subject__content:before {
  background-color: #E5E3E3;
}
.subject__section--side .subject__content:before {
  background-color: #F4F2F2;
}
.unit--subjectUnit {
  float: left;
  width: 100%;
  position: relative;
  z-index: 3;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.unit--subjectUnit .unit__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.unit--subjectUnit .unit__body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
#edit .unit--subjectUnit {
  outline-color: #71CAF3;
  min-height: 142px;
}
.subject--health .unit--subjectUnit .part--linkArrow .open {
  color: #288E7F;
  background-image: url(/images/arrow-right-health.svg);
}
.subject--gastro .unit--subjectUnit .part--linkArrow .open {
  color: #9B876B;
  background-image: url(/images/arrow-right-gastro.svg);
}
.subject--hotel .unit--subjectUnit .part--linkArrow .open {
  color: #7C58A7;
  background-image: url(/images/arrow-right-hotel.svg);
}
.subject--clothes .unit--subjectUnit .part--linkArrow .open {
  color: #498CB5;
  background-image: url(/images/arrow-right-clothes.svg);
}
.unit--subjectSideUnit .unit__body {
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.unit--subjectSideUnit .part {
  margin-top: 0;
  margin-bottom: 0;
}
.unit--subjectSideUnit .part.text {
  padding-left: 40px;
  position: relative;
  padding-bottom: calc(var(--spacePart) * 2);
  box-sizing: border-box;
}
.unit--subjectSideUnit .part.text:before,
.unit--subjectSideUnit .part.text:after {
  content: '';
  position: absolute;
  top: 0;
  z-index: 2;
}
.unit--subjectSideUnit .part.text:before {
  left: 10px;
  width: 4px;
  height: 100%;
  z-index: 2;
}
.unit--subjectSideUnit .part.text:after {
  left: 0;
  z-index: 3;
  width: 24px;
  height: 24px;
  border-radius: 10000px;
}
.unit--subjectSideUnit .part.text:last-child {
  padding-bottom: 0;
}
.unit--subjectSideUnit .part.text:last-child:before {
  display: none;
}
.subject--health .unit--subjectSideUnit .part.text:before,
.subject--health .unit--subjectSideUnit .part.text:after {
  background-color: #288E7F;
}
.subject--gastro .unit--subjectSideUnit .part.text:before,
.subject--gastro .unit--subjectSideUnit .part.text:after {
  background-color: #9B876B;
}
.subject--hotel .unit--subjectSideUnit .part.text:before,
.subject--hotel .unit--subjectSideUnit .part.text:after {
  background-color: #7C58A7;
}
.subject--clothes .unit--subjectSideUnit .part.text:before,
.subject--clothes .unit--subjectSideUnit .part.text:after {
  background-color: #498CB5;
}
.cbdModule--productFaderTitle {
  position: absolute;
  top: var(--verticalSpaceLarge);
  left: 50%;
  transform: translateX(-50%);
  width: var(--deskWidth);
  max-width: var(--deskMaxWidth);
  z-index: 5;
  text-align: center;
  padding-top: 16px;
}
.cbdModule--productFaderTitle:before {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width: 140px;
  height: 4px;
  z-index: 2;
  background-color: #000;
}
.cbdModule--productFader {
  margin-top: calc(var(--verticalSpaceLarge) + (var(--spaceTotal) * 2) + 62px);
  padding-bottom: var(--verticalSpaceLarge);
  position: relative;
}
.cbdModule--productFader:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: calc(100% - var(--productFaderSpaceTop));
  background-color: #BCBBBC;
}
@media (max-width: 1023px) {
  .cbdModule--productFader {
    margin-top: calc(var(--verticalSpaceLarge) + (var(--spaceTotal) * 2) + 54px);
  }
}
.ns-sliderWrapper--productFader {
  float: left;
  width: 100%;
  position: relative;
  z-index: 2;
}
.ns-productFader {
  float: left;
  width: 100%;
}
.ns-productFader__content {
  float: left;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ns-productFader__desk {
  float: left;
  width: 850px;
  max-width: var(--deskMaxWidth);
}
.ns-slider__stage {
  float: left;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.ns-slide {
  float: left;
  width: 100%;
  flex-shrink: 0;
  margin-right: -100%;
  opacity: 0;
  position: relative;
  z-index: 3;
  pointer-events: none;
  transition: opacity 0.4s;
}
.ns-slide.is-active {
  opacity: 1;
  z-index: 4;
  pointer-events: initial;
}
.ns-slide__content {
  float: left;
  width: calc(100% + 40px);
  margin-left: -20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1023px) {
  .ns-slide__content {
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    margin-left: 0;
  }
}
.ns-slider__part {
  float: left;
  margin-left: 20px;
  margin-right: 20px;
  opacity: 0;
  transform: translateX(-100px);
  transition: all 0.2s;
}
.ns-slide.is-active .ns-slider__part {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.4s 0.2s;
}
@media (max-width: 1023px) {
  .ns-slider__part {
    margin-left: 0;
    margin-right: 0;
  }
}
.ns-slider__part--image {
  width: calc(100% - 40px);
  height: 554px;
}
.ns-slider__part--image .ns-sliderImage {
  height: 100%;
  object-fit: contain;
  object-position: center;
}
@media (max-width: 1023px) {
  .ns-slider__part--image {
    max-width: calc(100% - 160px);
    height: 320px;
  }
}
@media (max-width: 767px) {
  .ns-slider__part--image {
    max-width: calc(100% - 80px);
    height: 160px;
  }
}
.ns-slider__part--text {
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 40px;
}
.ns-slider__part--text .text-section {
  max-width: 590px;
}
.ns-slide.is-active .ns-slider__part--text {
  transition: all 0.4s 0.3s;
}
.ns-slider__part--link {
  margin-top: 40px;
}
#view .ns-slider__part--link.is-empty {
  display: none;
}
.ns-slider__part--link .open {
  float: left;
  background-color: transparent;
  font-size: 18px;
  line-height: 1.44444444;
  font-family: inherit;
  cursor: pointer;
  color: #000;
  padding: 5px 40px;
  appearance: none;
  border-radius: 10000px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  border: 2px solid #000;
  transition: all 0.24s;
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #D80011, #D80011);
}
.ns-slider__part--link .open:hover,
.ns-slider__part--link .open:focus {
  background-size: 100% 100%;
  border-color: #D80011;
  color: #fff;
}
.ns-slide.is-active .ns-slider__part--link {
  transition: all 0.4s 0.4s;
}
@media (max-width: 1023px) {
  .ns-slider__part--link + .ns-slider__part--link {
    margin-top: 20px;
  }
}
.ns-sliderControl {
  position: absolute;
  top: var(--productFaderSpaceTop);
  transform: translateY(-50%);
  z-index: 5;
  width: 79px;
  aspect-ratio: 0.51298701;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  cursor: pointer;
}
@media (max-width: 1023px) {
  .ns-sliderControl {
    width: 60px;
  }
}
@media (max-width: 767px) {
  .ns-sliderControl {
    width: 22px;
  }
}
.ns-sliderControl--prev {
  left: var(--deskSpace);
  background-image: url(/images/productFader-prev.svg);
}
.ns-sliderControl--next {
  right: var(--deskSpace);
  background-image: url(/images/productFader-next.svg);
}
.cbdModule--productTeaserSimilarTitle {
  margin-top: calc(var(--verticalSpaceLarge) - var(--spaceTotal));
  margin-bottom: var(--spaceTotal);
  position: relative;
  z-index: 2;
  text-align: center;
}
.cbdModule--productTeaserSimilarTitle h2 {
  padding-top: 16px;
  position: relative;
}
.cbdModule--productTeaserSimilarTitle h2:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 140px;
  height: 4px;
  background-color: #000;
}
.area--two .unitOne--1-1 .cbdModule--productTeaserSimilarTitle h2:before,
.cbdModule--productTeaserSimilarTitle.cbdModule--productTeaserSimilarTitle h2:before,
.area--one .unitThree .cbdModule--productTeaserSimilarTitle h2:before {
  left: 50%;
  transform: translateX(-50%);
}
.ns-teaserWrapper {
  float: left;
  width: 100%;
  margin-top: var(--verticalSpaceLarge);
  margin-bottom: var(--verticalSpaceLarge);
}
.section--productTeaserSimilar .ns-teaserWrapper {
  margin-top: calc(var(--verticalSpaceLarge) - var(--spaceTotal));
}
.ns-teaserContent {
  float: left;
  width: 100%;
}
.ns-teaserArea {
  float: left;
  width: calc(100% + 30px);
  margin: calc(var(--spaceTotal) * -1) -15px;
  display: flex;
  flex-wrap: wrap;
}
.ns-teaser {
  float: left;
  width: calc((100% / 3) - 30px);
  margin: var(--spaceTotal) 15px;
  background-color: #E5E3E3;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #000 !important;
  min-height: 530px;
  text-decoration: none;
}
@media (max-width: 1023px) {
  .ns-teaser {
    width: calc(50% - 30px);
  }
}
@media (max-width: 767px) {
  .ns-teaser {
    width: calc(100% - 30px);
  }
}
.ns-teaser__content {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  box-sizing: border-box;
  padding: 0 40px 40px;
}
.ns-teaser__part {
  float: left;
}
.ns-teaser__part--productImage {
  height: 260px;
  margin-top: -40px;
}
.ns-teaser__part--productImage .ns-teaserImage {
  height: 100%;
  object-fit: contain;
  object-position: center top;
}
.ns-teaser__part--productText {
  margin-top: 40px;
}
.ns-teaser__part--productLink {
  float: left;
  margin-top: auto;
  padding-top: 40px;
}
.ns-teaser__part--productLink .ns-teaserLink {
  float: left;
  font-size: 18px;
  line-height: 1.44444444;
  color: #D80011;
  text-decoration: none;
  padding-left: 76px;
  padding-right: 10px;
  background-size: auto 16px;
  background-position: 0 5px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-red.svg);
  transition: all 0.24s;
  transform: scale(1.03);
}
.ns-teaser__part--productLink .ns-teaserLink:hover,
.ns-teaser__part--productLink .ns-teaserLink:focus {
  padding-right: 0;
  padding-left: 86px;
  color: #000 !important;
  background-image: url(/images/arrow-right-black.svg) !important;
}
.ns-teaser:hover .ns-teaser__part--productLink .ns-teaserLink {
  padding-right: 0;
  padding-left: 86px;
  color: #000 !important;
  background-image: url(/images/arrow-right-black.svg) !important;
}
.cbdModule--filterTitle {
  margin-top: var(--verticalSpaceLarge);
  position: relative;
  z-index: 2;
  margin-bottom: calc((var(--verticalSpaceLarge) - (var(--spacePart) * 2)) * -1);
}
.cbdModule--filterTitle h2 {
  padding-top: 16px;
  position: relative;
}
.cbdModule--filterTitle h2:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 140px;
  height: 4px;
  background-color: #000;
}
.area--two .unitOne--1-1 .cbdModule--filterTitle h2:before,
.cbdModule--productTeaserSimilarTitle.cbdModule--filterTitle h2:before,
.area--one .unitThree .cbdModule--filterTitle h2:before {
  left: 50%;
  transform: translateX(-50%);
}
.cbdModule--productFilter {
  margin-top: var(--verticalSpaceLarge);
  margin-bottom: var(--verticalSpaceLarge);
}
.ns-filter {
  float: left;
  width: 100%;
}
.ns-filter__content {
  float: left;
  width: 100%;
}
.ns-filter__cover {
  float: left;
  width: calc(100% + 60px);
  margin: -30px;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .ns-filter__cover {
    width: calc(100% + 20px);
    margin: -10px;
  }
}
.ns-filter__item {
  float: left;
  margin: 30px;
  width: calc(25% - 60px);
}
@media (max-width: 1023px) {
  .ns-filter__item {
    width: calc(50% - 60px);
  }
}
@media (max-width: 767px) {
  .ns-filter__item {
    margin: 10px;
    width: calc(50% - 20px);
  }
}
.ns-dropdown {
  float: left;
  width: 100%;
  font-size: 18px;
  line-height: 1.44444444;
}
@media (max-width: 767px) {
  .ns-dropdown {
    font-size: 15px;
    line-height: 1.2;
  }
}
.ns-dropdown__toggle {
  float: left;
  text-decoration: none;
  color: #000 !important;
  font-weight: 600;
}
.ns-dropdown__content {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 14px;
}
.ns-dropdown__item {
  float: left;
  text-decoration: none;
  padding-left: 40px;
  position: relative;
  min-height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 14px;
  color: #000 !important;
}
.ns-dropdown__item:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border: 2px solid #000;
  border-radius: 10000px;
  background-size: 12px 12px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.ns-dropdown__item:first-child {
  margin-top: 0;
}
.ns-dropdown__item:hover {
  color: #D80011 !important;
}
.ns-dropdown__item:hover:before {
  border-color: #D80011;
}
.ns-dropdown__item.is-active:before {
  background-image: url(/images/icon-x-black.svg);
}
.ns-dropdown__item.is-active:hover:before {
  background-image: url(/images/icon-x-red.svg);
}
@media (max-width: 767px) {
  .ns-dropdown__item {
    hyphens: auto;
    padding-left: 26px;
    min-height: 20px;
  }
  .ns-dropdown__item:before {
    width: 20px;
    height: 20px;
    background-size: 8px 8px;
  }
}
/*# sourceMappingURL=./screen-small.css.map */